<template>
    <v-container
        class="fill-height justify-center backcolor text-center pa-0 mb-0 mt-10"
        fluid>
            <v-row align="center" justify="center">
                <span class="text-h4 font-weight-ligh headline text-uppercase">
                    Trabajando Juntos
                </span>
            </v-row>
            <v-row justify="center">
                <div class="boxdivider"></div>
            </v-row>
            <v-row align="center" class="pt-8 pb-8 px-5 px-md-10" justify="center">
                <v-col v-for="(item, index) in cardItems" :key="index"
                       cols="12"
                       md="6">
                    <div class="mx-1 mx-md-5 fill-height fluid">
                        <a v-bind:href="item.linkextra" style="text-decoration: none !important;">
                            <v-img
                                :src="item.backimageurl"
                                class="rounded-lg elevation-15 align-center"
                                height="400px"
                                transition="scroll-x-transition"
                                width="100%">
                                <div class="fill-height align-center fluid blend-opacity"
                                    style="height: 400px">
                                    <v-layout
                                        align-content-center
                                        child-flex
                                        column
                                        fill-height
                                        justify-center
                                        wrap>
                                        <v-flex align-self-center shrink>
                                            <span
                                                class="white--text font-weight-light pa-2 text-center text-no-wrap text-uppercase">
                                                {{ item.title }}
                                            </span>
                                        </v-flex>
                                        <v-flex align-self-center shrink>
                                            <v-btn
                                                :to="item.link"
                                                class="ma-1"
                                                plain
                                                text
                                                transparent>
                                                <span class="white--text text-h4 font-weight-bold mb-3 headline pa-2 text-center text-no-wrap text-uppercase">
                                                    {{ item.subtitle }}
                                                </span>
                                            </v-btn>
                                        </v-flex>
                                    </v-layout>
                                </div>
                            </v-img>
                        </a>
                    </div>
                </v-col>
            </v-row>
    </v-container>
</template>

<script>
export default {
    name: "WorkTogetherComponent",
    data: () => ({
        cardItems: [
            {
                backimageurl: require("@/assets/images/hospitales.jpg"),
                title: "Nuestro Catálogo confiable de",
                subtitle: "Hospitales",
                link: "/hospitals",
                linkextra: "#/hospitals",
                flex: 6,
            },
            {
                backimageurl: require("@/assets/images/only-the-best-clinics.png"),
                title: "Solo las mejores",
                subtitle: "Clínicas",
                link: "/clinics",
                linkextra: "#/clinics",
                flex: 6,
            },
        ],
    }),
};
</script>

<style scoped>
.backcolor {
    background-color: transparent;
}

.blend-opacity {
    opacity: 0.2;
    transition: opacity 0.4s ease-in-out;
    background-color: black;
    background-blend-mode: normal;
}

.blend-opacity:hover {
    opacity: 0.5;
    transition: opacity 0.4s ease-in-out;
    background-color: black;
    background-blend-mode: darken;
}

.v-btn {
    text-transform: none !important;
}

.boxdivider {
    background-color: transparent;
    width: 100px;
    height: 20px;
    color: #ffffff;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom-width: 3px;
    border-bottom-color: blueviolet;
    border-bottom-style: solid;
    display: block;
}
</style>
