<template>
    <v-card width="100%" class="fill-height container--fluid pa-0 ma-0" flat tile>
        <v-slide-x-transition appear>
            <v-img src="@/assets/images/loby-clinics-hospitals.jpg"
                   class="grey lighten-2"
                   transition="slide-x-transition"
                   width="100%" height="500">
                <div class="fill-height bottom-gradient container--fluid" style="width: 100%">
                    <v-container fluid class="fill-height align-end pb-15">
                        <v-row align="end" justify="start">
                            <v-col cols="12" class="pl-5 pl-md-15">
                                <v-sheet outlined
                                         class="panel-filter-color"
                                         rounded="lg" light elevation="10" height="300"
                                         :width="$vuetify.breakpoint.xs ? '100%' : 600">
                                    <v-container fluid class="px-5 mx-5 py-8 my-8 pa-md-8 ma-md-8">
                                        <v-row align="left" justify="start" dense>
                                            <v-col cols="12">
                                                <div class="blue--text mx-1">
                                                    <span class="text-md-h4">
                                                        Lo mejor de Baja California
                                                    </span>
                                                </div>
                                                <div style="height: 30px" />
                                            </v-col>
                                            <v-col cols="12">
                                                <div class="indigo--text mx-1">
                                                    <span class="text-md-h5">Solo aceptamos</span>
                                                </div>
                                            </v-col>
                                            <v-col cols="12">
                                                <div class="indigo--text mx-1">
                                                    <span class="text-md-h5">Médicos Certificados</span>
                                                </div>
                                            </v-col>
                                        </v-row>
                                        <v-row align="left" justify="start" v-show="false">
                                            <v-responsive max-width="400">
                                                <div class="white--text mx-2">
                                                    <v-btn elevation="3" class="ma-2 white--text text-body-2" x-large color="cyan">
                                                        Hacer una Cita
                                                    </v-btn>
                                                </div>
                                            </v-responsive>
                                        </v-row>
                                    </v-container>
                                </v-sheet>
                            </v-col>
                        </v-row>
                    </v-container>
                </div>
            </v-img>
        </v-slide-x-transition>
    </v-card>
</template>

<script>
    export default {
        name: "HospitalsClinicsComponent",
    };
</script>

<style scoped>
    .bottom-gradient {
        background-image: linear-gradient(to bottom,
        rgba(114, 217, 235, 0.185) 0%,
        rgba(255, 255, 255, 0.356) 100%);
    }

    .panel-filter-color {
        background: rgba(255, 255, 255, 0.767) 100%;
    }
</style>
